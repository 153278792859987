import { Component, effect, signal, viewChild } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { Config } from './configs/index.config';
import { LoaderComponent } from 'ets-fe-ng-sdk';
import { SharedAppComponent } from 'projects/evolutics-shared-lib/src/lib/shared-app.component';
import { ChatHelpDeskComponent } from '../../../evolutics-shared-lib/src/lib/Shared/components/page-icons/chat-help-desk/chat-help-desk.component';
import { MatProgressBar } from '@angular/material/progress-bar';
import { UserPreviewModalComponent } from '@Shared/components/user-preview-modal/user-preview-modal.component';
import { ToastNotificationsComponent } from '@serene-dev/toast-notifications';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    MatProgressBar,
    LoaderComponent,
    RouterOutlet,
    ChatHelpDeskComponent,
    UserPreviewModalComponent,
    ToastNotificationsComponent,
  ],
})
export class AppComponent extends SharedAppComponent {
  readonly UserPreviewModalComponentRef = viewChild(UserPreviewModalComponent);
  readonly allowProfilePreview = signal(false);
 
  async ngOnInit() {
    super.ngOnInit();
    this.searchS.buildIndex();

    this.appS.systemMetadataMap = Config.systemMetadataMap as any;
    if (this.authService.isLoggedin) {
      this.authService.getAllFromLocal();
      lastValueFrom(this.transGlossary.getGlossary());
      this.authService.getAllFromOnline();
    }
    ['createdBy', 'updatedBy'].forEach((fieldName) => {
      this.uS.tableService.generalColumnPropMap[fieldName] = {
        onHoverHandler: {
          action: (row, event) => {
            if (this.allowProfilePreview())
              this.UserPreviewModalComponentRef()?.open(event as PointerEvent, {
                userName: row?.[fieldName],
              });
            else {
              this.allowProfilePreview.set(true);
              setTimeout(() => {
                this.UserPreviewModalComponentRef()?.open(event as PointerEvent, {
                  userName: row?.[fieldName],
                });
              }, 600);
            }
          },
          delay: 1000,
        },
      };
    });
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }
}
